@import "../vars";
@import "../mixins";

.text {
    @include container();

    margin-bottom: 4 * $base-spacing;

    @include styleBelow($container-size) {
        margin: 0 $base-spacing 4 * $base-spacing;
    }

    img {
        //padding: 5px;
        //border: 1px solid $grey;

        &.left {
            margin-right: 10px;
        }

        &.right {
            margin-left: 10px;
        }
    }

    p:first-of-type {
        margin-top: 0;
    }

    p:last-of-type {
        margin-bottom: 0;
    }

    a {
        color: $grey;
        &:hover {
            color: $red;
        }
    }
}