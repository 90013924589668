@import "vars";

$fa-font-path: "/website/static/fonts";
@import "../../node_modules/font-awesome/scss/font-awesome";

@import "../../node_modules/magnific-popup/src/css/main";

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: 'Open Sans', Arial ,sans-serif;
    color: $grey;
}

p {
    margin: $base-spacing  0 $base-spacing 0;
}

a {
    text-decoration: none;
    color: $dark-blue;
}

h1, h2, h3, h4 {
    font-family: 'Oswald', sans-serif;
    font-variant: all-small-caps;
    color: $dark-blue;
    margin: $base-spacing 0;
    font-weight: 700;
}

.edit {
    display: inline-block;
}

@import "editmode";

@import "blocks/navigation";
@import "blocks/slogan";
@import "blocks/logo";
@import "blocks/banner";
@import "blocks/text";
@import "blocks/address";
@import "blocks/headline";
@import "blocks/staff";
@import "blocks/gallery";
@import "blocks/highlights";
@import "blocks/menu";

/* oswald-regular - latin */
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 400;
    src: url('https://cdn-fonts.backslash-n.io/oswald/oswald-v49-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('https://cdn-fonts.backslash-n.io/oswald/oswald-v49-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('https://cdn-fonts.backslash-n.io/oswald/oswald-v49-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('https://cdn-fonts.backslash-n.io/oswald/oswald-v49-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('https://cdn-fonts.backslash-n.io/oswald/oswald-v49-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('https://cdn-fonts.backslash-n.io/oswald/oswald-v49-latin-regular.svg#Oswald') format('svg'); /* Legacy iOS */
}
/* oswald-700 - latin */
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 700;
    src: url('https://cdn-fonts.backslash-n.io/oswald/oswald-v49-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('https://cdn-fonts.backslash-n.io/oswald/oswald-v49-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('https://cdn-fonts.backslash-n.io/oswald/oswald-v49-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('https://cdn-fonts.backslash-n.io/oswald/oswald-v49-latin-700.woff') format('woff'), /* Modern Browsers */
    url('https://cdn-fonts.backslash-n.io/oswald/oswald-v49-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('https://cdn-fonts.backslash-n.io/oswald/oswald-v49-latin-700.svg#Oswald') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('https://cdn-fonts.backslash-n.io/open-sans/open-sans-v34-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('https://cdn-fonts.backslash-n.io/open-sans/open-sans-v34-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('https://cdn-fonts.backslash-n.io/open-sans/open-sans-v34-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('https://cdn-fonts.backslash-n.io/open-sans/open-sans-v34-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('https://cdn-fonts.backslash-n.io/open-sans/open-sans-v34-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('https://cdn-fonts.backslash-n.io/open-sans/open-sans-v34-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-500 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    src: url('https://cdn-fonts.backslash-n.io/open-sans/open-sans-v34-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('https://cdn-fonts.backslash-n.io/open-sans/open-sans-v34-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('https://cdn-fonts.backslash-n.io/open-sans/open-sans-v34-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('https://cdn-fonts.backslash-n.io/open-sans/open-sans-v34-latin-500.woff') format('woff'), /* Modern Browsers */
    url('https://cdn-fonts.backslash-n.io/open-sans/open-sans-v34-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('https://cdn-fonts.backslash-n.io/open-sans/open-sans-v34-latin-500.svg#OpenSans') format('svg'); /* Legacy iOS */
}
