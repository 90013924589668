@import "../vars";
@import "../mixins";

$menu-breakpoint: 500px;
$arrow-size: 14px;

$low-w: 560px;

.navigation {
    background-color: #000;
    color: #fff;

    &__main {
        display: flex;
        justify-content: center;
        position: relative;

        margin: 0 auto;

        @include styleBelow($low-w) {
            display: none;
        }
    }
    &__home {
        text-decoration: none;
        padding: 10px 15px;
        font-size: 1.5em;

        @include styleBelow($low-w) {
            display: none;
        }

        a {
            font-size: 1.2em;
            font-family: 'Oswald', sans-serif;
            color: #fff;

            @include styleBelow($low-w) {
                font-weight: bold;
                text-transform: uppercase;
            }

            &:hover {
                color: $red;
            }
        }
        &--active {
            color: $red;
            padding: 10px 15px;
            font-size: 1.5em;

            @include styleBelow($low-w) {
                display: none;
            }

            a {
                font-size: 1.2em;
                font-family: 'Oswald', sans-serif;
                color: $red;

                @include styleBelow($low-w) {
                    font-weight: bold;
                    text-transform: uppercase;
                }

                &:hover {
                    color: $red;
                }
            }

            @include styleBelow($low-w) {
                margin-top: 0;
                padding: $base-spacing*2 $base-spacing;
            }
        }
    }

    &__sub {
        display: flex;
        justify-content: center;

        background-color: $dark-blue;

        &--overlay {
            position: absolute;
            width: 100%;
        }

        &--hidden {
            display: none;
        }

        @include styleBelow($low-w) {
            display: none;
        }
    }
    &__sublink {
        color: #fff;
        margin: $base-spacing;
        padding-bottom: $base-spacing /4;
        &--active {
            border-bottom: 2px solid $red;
        }
    }

    &__box {
        display: inline-block;
        padding: $base-spacing;
        position: relative;

        a {
            font-size: 1.5em;
            font-family: 'Oswald', sans-serif;
            color: #fff;

            &:hover {
                color: $red;
            }
        }

        &--active {
            .navigation__arrow {
                display: block;
            }

            a {
                color: $red;
            }
        }

    }

    &__arrow {
        position: absolute;
        height: 0;
        width: 0;
        bottom: 0;
        left: calc(50% - #{$arrow-size} / 2);

        display: none;

        border-left: $arrow-size / 2 solid transparent;
        border-right: $arrow-size / 2 solid transparent;
        border-bottom: $arrow-size / 2 solid #fff;

        &--blue {
            border-bottom: $arrow-size / 2 solid $dark-blue;
        }
    }
}