@import "../vars";
@import "../mixins";

.menu {
    display: none;
    position: fixed;
    background-color: #000;
    color: #fff;
    border-left: 1px solid #fff;

    height: 100vh;

    top: 0;
    right: 0;

    z-index: 110;

    a {
        color: #fff;
        display: block;
        padding: $base-spacing;
    }

    &__1st {
        border-bottom: 1px solid #fff;
        &--active {
            background-color: $red;
            a {
                color: #fff;
            }
        }
    }

    &__2nd {
        border-bottom: 1px solid #fff;

        a {
            padding-left: $base-spacing * 3;
        }
    }
}

.black-overlay {
    display: none;

    background-color: rgba(0, 0, 0, 0.56);
    position: fixed;

    top: 0;
    left: 0;

    height: 100vh;
    width: 100vw;
    z-index: 100;
}