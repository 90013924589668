@import "../vars";
@import "../mixins";

.highlights {

    $dot-size: 35px;
    $image-size: 150px;

    @include container();
    @include grid();

    @include styleBelow($container-size) {
        margin: 0 $base-spacing $base-spacing * 2;
    }

    justify-content: center;
    margin-bottom: $base-spacing * 2; // last row of images add another base-spacing

    a {
        display: block;
    }

    &__element {

        @include styleAbove($container-size + 1) {
            @include centerItem($container-size, 5, $base-spacing * 2);
        }

        @include styleBetween(500px, $container-size) {
            @include centerItem(100%, 3, $base-spacing * 2);
        }

        @include styleBelow(499px) {
            @include centerItem(100%, 2, $base-spacing * 2);
        }
        @include  styleBelow(400px) {
            @include  centerItem(100%, 1, $base-spacing * 2);
        }


        position: relative;

        &:hover .highlights__dot i {
            transform: rotate(-90deg);

        }
    }

    &__image {

        img {
            display: block;
            border-radius: 50%;
            margin: 0 auto;
            height: $image-size;
            border: 3px solid $dark-blue;
        }

        margin-bottom: $base-spacing * 1.5;
    }

    &__dot {
        position: absolute;
        background-color: $red;
        height: $dot-size;
        width: $dot-size;

        border-radius: 50%;

        left: calc(50% - #{$dot-size / 2});
        top: $image-size - $dot-size / 2 - 4;

        text-align: center;
        color: #fff;
        line-height: $dot-size;

        i {
            transition: transform 0.2s;
        }
    }

    &__text {
        text-align: center;

        h3 {
            color: $grey;
            font-variant: none;
            margin: 0;
            font-size: 1.5em;
            font-weight: 400;
            text-align: center;
        }
    }
}