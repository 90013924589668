@import "../vars";
@import "../mixins";

.address {
    display: flex;
    flex-direction: row;

    @include styleBelow(700px) {
        flex-direction: column;
    }

    &__map {
        flex: 2;

        @include styleBelow(700px) {
            height: 500px;
            order: 2;
        }
    }

    &__contact {
        flex: 1;
        background-color: $dark-blue;
        color: #fff;

        h3 {
            color: #fff;
            margin: 0;
            font-size: 1.5em;
        }

        @include styleBelow(700px) {
            order: 1;
        }
    }

    &__block {
        border-bottom: 1px solid #fff;
        margin: 0 $base-spacing * 4;
        padding: $base-spacing 0;
        a {
            color: #fff;
            &:hover {
                color: $red;
            }
        }

        &:last-of-type {
            border-bottom: none;
        }
    }
}