@import "../vars";
@import "../mixins";

.gallery {
    @include container();
    @include grid();

    margin-bottom: $base-spacing * 3; // last row of images add another base-spacing


    @include styleBelow($container-size) {
        margin: 0 $base-spacing $base-spacing * 3;
    }

    a {
        color: #fff;
    }

    &__image {
        @include styleAbove($container-size + 1) {
            @include gridItem($container-size, 4, $base-spacing);
        }

        @include styleBetween(500px, $container-size) {
            @include gridItem(100%, 3, $base-spacing);
        }

        @include styleBelow(499px) {
            @include gridItem(100%, 2, $base-spacing);
        }

        position: relative;

        img {
            max-width: 100%;
            display: block;
        }
    }

    &__button {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 100;
        font-size: 2em;
        color: #fff;
        background-color: $red;
    }

}