@import "../vars";
@import "../mixins";

.headline {
    @include container();

    display: flex;
    justify-content:  center;

    position: relative;
    text-align: center;

    @mixin headline($fontsize) {
        font-family: 'Oswald', sans-serif;
        font-variant: all-small-caps;
        color: $dark-blue;
        font-size: $fontsize;
        background-color: #fff;

        line-height: 100%;

        //z-index: 1;
        margin: $base-spacing;
        margin-top: -1 * $base-spacing; // workaround for all-small-caps
        padding: 0 $base-spacing / 2;
    }

    h1 {
        @include headline(2.5em)
    }

    h2 {
        @include headline(2em)
    }

    @mixin line($width, $bottom) {
        position: absolute;
        height:0;
        width: $width;
        bottom: $bottom;
        left: calc(50% - #{$width} / 2);
        border-bottom: 1px solid $light-grey;

        z-index: -1;

        @include styleBelow(400px) {
            $width: $width / 2;
            width: $width;
            left: calc(50% - #{$width} / 2);
        }
    }

    &__border-top {
        @include line($container-size / 6, $base-spacing + 15px);
    }

    &__border-mid {
        @include line($container-size / 4, $base-spacing + 10px);
    }

    &__border-bot {
        @include line($container-size / 3, $base-spacing + 5px);
    }
}