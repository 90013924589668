@import "../vars";
@import "../mixins";

.logo {
    $medium-w: 860px;
    $low-w: 560px;

    display: flex;
    @include container();

    @include styleBelow($container-size) {
        margin: 0 $base-spacing;
    }

    @include styleBelow($medium-w) {
        flex-direction: column;
        margin: 0;
    }

    &__image {
        img {
            display: block;
            margin-bottom: $base-spacing * 1.2;
            margin-top: $base-spacing * 1.2;

            @include styleBelow($medium-w) {
                margin: $base-spacing * 1.2 auto;
                width: 70%;
            }

            @include styleBelow($low-w) {
                margin: $base-spacing * 1.2;
                width: 50%;
            }
            @include styleBelow(350px) {
                width: 50%;
                margin-right: 5px;
            }
        }
    }

    &__collapse {
        background-color: #000;
        color: #fff;
        display: none;
        font-size: 1.6em;
        padding: $base-spacing;
        font-family: 'Oswald', sans-serif;

        @include styleBelow($medium-w) {
            display: flex;
            flex-direction: row;
        }

        &-l {
            display: block;
            flex: 1
        }

        &-r {
            display: block;
            font-size: 1.6em;
        }
    }

    &__address {
        font-size: 1.3em;
        padding: $base-spacing * 1.2;
        line-height: 1.8em;

        @include styleBelow($medium-w) {
            background-color: #000;
            color: #fff;
            display: none;
            padding: $base-spacing;
            border-top: 1px solid #fff;
        }
        a {
            font-size: 1em;
            color: $grey;
            &:hover {
                color: $red;
            }
        }
    }

    &__spacer {
        flex: 1;

        @include styleBelow($medium-w) {
            flex: 0;
        }
    }

    &__menu-button {
        display: none;

        position: absolute;
        color: #fff;
        background-color: #000;
        right: $base-spacing;
        top: 40px;
        font-size: 2.5em;
        padding: $base-spacing / 2 $base-spacing / 3;

        i {
            display: block;
        }

        @include styleBelow($low-w) {
            display: block;
        }
    }
}