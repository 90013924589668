@import "../vars";
@import "../mixins";

.staff {
    @include container(900px);
    @include grid();
    justify-content: center;
    @include styleBelow(900px) {
        margin: 0 $base-spacing $base-spacing * 2;
    }

    &__employee {
        @include gridItem(100%, 3, $base-spacing * 6);

        @include styleBelow(820px){
            @include gridItem(100%, 2, $base-spacing * 6);
        }

        @include styleBelow(500px) {
            @include gridItem(100%, 1, $base-spacing * 6);

        }

    }

    &__photo {
        border-bottom: 5px solid $red;

        img {
            display: block;
            max-width: 100%;
        }
    }

    &__description {
        background-color: $grey;
        color: #fff;
        padding: 0 $base-spacing $base-spacing;
        font-family: 'Oswald', sans-serif;

        h3 {
            color: #fff;
            margin: 0;
            font-size: 1.6em;
        }
    }

    &__position {
        display: block;
        margin-bottom: $base-spacing / 2;
    }

    &__tel {
        display: block;
        font-family: 'Open Sans', sans-serif;
    }

    &__mail {
        display: block;
        font-family: 'Open Sans', sans-serif;
        color: #fff;
        &:hover {
            color: $red;
        }
    }
}