@import "../vars";
@import "../mixins";

$arrow-size: 15px;

.slogan {
    background-color: $dark-blue;
    color: #fff;
    font-family: 'Poiret One', cursive;
    position: relative;

    margin-bottom: $base-spacing * 4;


    &__text {
        @include container($container-size / 2);
        margin: 0 auto;
        font-size: 1.5em;
        padding-top: $base-spacing * 1.2;
        padding-bottom: $base-spacing * 1.2;
        text-align: center;
    }

    &__arrow {
        position: absolute;
        height: 0;
        width: 0;
        bottom: 0;
        left: calc(50% - #{$arrow-size});

        border-left: $arrow-size solid transparent;
        border-right: $arrow-size solid transparent;
        border-bottom: $arrow-size solid #fff;
    }

}