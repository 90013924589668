// colors
$red: #bf0200;
$blue: #24336e;
$dark-blue: #181f49;
$light-blue: #53648a;
$grey: #454545;
$light-grey: #ccc;

$base-spacing: 15px;

$container-size: 1200px;